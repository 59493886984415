import { useState } from 'react';
import Slider from 'react-slick';
import Image from './Image';
import { Slides } from '../../utils/mapper/Mapper';

const TruQStars = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: false,
    speed: 500,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssease: "linear",
    pauseOnHover: true,
    centerMode: true,
    beforeChange: (current: any) => setCurrentIndex(current),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          beforeChange: (_: any, next: number) => setCurrentIndex(next),
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          beforeChange: (_: any, next: number) => setCurrentIndex(next),
        }
      }
    ]
  };

  return (
    <section className="d-flex flex-column flex-lg-row gap-lg-5 px-3 px-md-0">
      <div className="w-lg-60-p my-auto mt-lg-130 ms-0 ms-lg-5">
        <div className="w-600 w-100 text-center text-lg-start px-5">
          <h4 className="line-spacing-05">Best Place To Work</h4>
          <p className="font-size-20 mt-4">
            We are building the most desirable place anyone would  want
            to work; fun, result driven, fulfilling, welcoming and
            inclusive
            <br />
          </p>
        </div>
      </div>
      <div className="w-lg-40-p mt-5 mt-lg-0 my-5 flaot-right">
        <Slider {...settings}>
          {Slides.map((item, index) =>
            <div key={index} className={`px-2 ms-170 ${currentIndex === index ? 'slide-active' : 'slide-inactive'}`}>
              <Image src={item.src} alt={item.alt} className='image-fluid rounded-3' width={100} height={100} />
            </div>
          )}
        </Slider>
      </div>
    </section>
  );
}

export default TruQStars;