import Faqs from "../../assets/images/svgs/Faqs.svg";
import Demo from "../../assets/images/svgs/Demo.svg";
import Career from "../../assets/images/svgs/Career.svg";
import {IHeader, IProduct} from "../../modules/components";
import AboutUs from "../../assets/images/svgs/about-us.svg";
import ApiDocs from "../../assets/images/svgs/api-docs.svg";
import DriverHub from "../../assets/images/svgs/drivehub.svg";
import SliderImage3 from "../../assets/images/pngs/slide3.jpg";
import ContactUs from "../../assets/images/svgs/contact-us.svg";
import UserGuide from "../../assets/images/svgs/user-guide.svg";
import SijuByTruq from "../../assets/images/svgs/siju-by-truQ.svg";
import MoveByTruq from "../../assets/images/svgs/move-by-truQ.svg";
import solution1 from "../../assets/images/pngs/truQPay/solution1.png";
import solution2 from "../../assets/images/pngs/truQPay/solution2.png";
import solution3 from "../../assets/images/pngs/truQPay/solution3.png";
import feature1 from "../../assets/images/pngs/brands/Payaza.png";
import feature2 from "../../assets/images/pngs/brands/Google.png";
import feature3 from "../../assets/images/pngs/brands/Yalo.png";
import feature4 from "../../assets/images/pngs/brands/VFD.png";
import feature5 from "../../assets/images/pngs/brands/Ulospaces.png";
import feature6 from "../../assets/images/pngs/brands/Paystack.png";
import feature7 from "../../assets/images/pngs/brands/ProvidusBank.png";
import feature8 from "../../assets/images/pngs/brands/SeeFi.png";
import feature9 from "../../assets/images/pngs/brands/My-cover-ai.png";
import feature10 from "../../assets/images/pngs/brands/DriveMe.png";
import feature11 from "../../assets/images/pngs/brands/Curacell.png";
import feature12 from "../../assets/images/pngs/brands/Lenco.png";
import PartnershipBase from "../../assets/images/svgs/partnership.svg";
import chartSVG from "../../assets/images/svgs/presention-chart.svg";
import cardSVG from "../../assets/images/svgs/card-pos.svg";
import moneysSVG from "../../assets/images/svgs/moneys.svg";
import peopleSVG from "../../assets/images/svgs/people.svg";
import {LinkType, MenuType, NavItemType, SubMenuType, ColumnOrder} from "../../modules/enums";


// HEADER DATA
export const NavItems = [
  {
    name: "Products",
    type: NavItemType.PRODUCTS
  },
  {
    name: "Company",
    type: NavItemType.COMPANY
  },
  {
    name: "Resources",
    type: NavItemType.RESOURCES
  }
]

export const ProductsData: IHeader[] = [
  {
    alt: "TruQ_App",
    src: DriverHub,
    title: "TruQ App",
    subtitle: "For drivers and vehicle owners",
    link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_APP}`,
    link_type: LinkType.IN_APP
  },
  {
    alt: "Move_By_Truq",
    src: MoveByTruq,
    title: "Move by truQ",
    subtitle: "For house moves and partners",
    link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ}`,
    link_type: LinkType.IN_APP
  },
  // {
  //   imgAlt: "Truq_Pay",
  //   imgSrc: TruqPay,
  //   title: "truQ Pay",
  //   subtitle: "Lorem ipsum dolor sit amet",
  //   link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_PAY}`,
  //   link_type: LinkType.IN_APP
  // },
  {
    alt: "Siju_By_TruQ",
    src: SijuByTruq,
    title: "Siju by truQ",
    subtitle: "For businesses",
    link: `/${MenuType.PRODUCTS}/${SubMenuType.SIJU}`,
    link_type: LinkType.IN_APP
  }
]

export const CompanyData: IHeader[] = [
  {
    alt: "About_Us",
    src: AboutUs,
    title: "About Us",
    subtitle: "Who we are and what we do",
    link: `/${MenuType.COMPANY}/${SubMenuType.ABOUT_US}`,
    link_type: LinkType.IN_APP
  },
  {
    alt: "Contact_Us",
    src: ContactUs,
    title: "Contact Us",
    subtitle: "Get in touch with us",
    link: `/${MenuType.COMPANY}/${SubMenuType.CONTACT_US}`,
    link_type: LinkType.IN_APP
  },
  {
    alt: "Career",
    src: Career,
    title: "Career",
    subtitle: "Become a truQstar today!",
    link: `/${MenuType.COMPANY}/${SubMenuType.CAREER}`,
    link_type: LinkType.IN_APP
  },
]

export const ResourcesData: IHeader[] = [
  {
    alt: "user_guide",
    src: UserGuide,
    title: "Users Guide",
    subtitle: "Learn how truQ works",
    link: "https://docs.mytruq.com/",
    link_type: LinkType.TARGET
  },
  {
    alt: "Api_Docs",
    src: ApiDocs,
    title: "API Docs",
    subtitle: "Access our API guides and documentation",
    link: "https://truq-services.gitbook.io",
    link_type: LinkType.TARGET
  },
  {
    alt: "Demo_Video",
    src: Demo,
    title: "Demo Video",
    subtitle: "Explore our support resources",
    link: `/${MenuType.CONSTRUCTION}`,
    link_type: LinkType.IN_APP
  },
  {
    alt: "Partnership_Base",
    src: PartnershipBase,
    title: "Partnership Base",
    subtitle: "Collaborate with us for growth",
    link: `/${MenuType.CONSTRUCTION}`,
    link_type: LinkType.IN_APP
  },
  {
    alt: "Blogs",
    src: SijuByTruq,
    title: "Blogs",
    subtitle: "Keep up with Insights & updates with truQ",
    link: `/${MenuType.CONSTRUCTION}/`,
    link_type: LinkType.IN_APP
  },
  {
    alt: "Faqs",
    src: Faqs,
    title: "FAQs",
    subtitle: "Find answers to common questions",
    link: `/${MenuType.CONSTRUCTION}`,
    link_type: LinkType.IN_APP
  },
]

// FOOTER DATA
export const FooterLinks = [
  {
    title: "Product",
    className: "order-1",
    content: [
      {
        text: "TruQ App",
        link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_APP}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Move by truQ",
        link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Siju by truQ",
        link: `/${MenuType.PRODUCTS}/${SubMenuType.SIJU}`,
        link_type: LinkType.IN_APP
      },
      // {
      //   text: "truQ Pay",
      //   link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_PAY}`,
      //   link_type: LinkType.IN_APP
      // },
    ]
  },
  {
    title: "Company",
    className: "order-2 order-md-3",
    content: [
      {
        text: "About Us",
        link: `/${MenuType.COMPANY}/${SubMenuType.ABOUT_US}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Contact Us",
        link: `/${MenuType.COMPANY}/${SubMenuType.CONTACT_US}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Career",
        link: `/${MenuType.COMPANY}/${SubMenuType.CAREER}`,
        link_type: LinkType.IN_APP
      },
    ]
  },
  {
    title: "Legal",
    className: "order-5 order-md-4",
    content: [
      {
        text: "Terms of Service",
        link: `/${MenuType.LEGAL}/${SubMenuType.TERMS_CONDITION}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Privacy Policy",
        link: `/${MenuType.LEGAL}/${SubMenuType.PRIVACY_POLICY}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Terms for Drivers and Partners",
        link: `/${MenuType.LEGAL}/${SubMenuType.TERMS_DRIVER_PATNER}`,
        link_type: LinkType.IN_APP
      },
    ]
  },
  {
    title: "Resources",
    className: "order-3 order-md-2",
    content: [
      {
        text: "Users Guide",
        link: "https://truq-services.gitbook.io",
        link_type: LinkType.TARGET
      },
      {
        text: "API Docs",
        link: "https://docs.mytruq.com/",
        link_type: LinkType.TARGET
      },
      {
        text: "FAQs",
        link: `/${MenuType.CONSTRUCTION}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Blog",
        link: `/${MenuType.CONSTRUCTION}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Demo Videos",
        link: `/${MenuType.CONSTRUCTION}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Partnerships Base",
        link: `/${MenuType.CONSTRUCTION}`,
        link_type: LinkType.IN_APP
      },
    ]
  },
  {
    title: "Socials",
    className: "order-4 order-md-5",
    content: [
      {
        text: "LinkedIn",
        link: "https://www.linkedin.com/company/truq-logistics/",
        link_type: LinkType.TARGET
      },
      {
        text: "Facebook",
        link: "https://web.facebook.com/Mytruq/",
        link_type: LinkType.TARGET
      },
      {
        text: "Instagram",
        link: "https://www.instagram.com/mytruq/",
        link_type: LinkType.TARGET
      },
      {
        text: "Twitter",
        link: "https://twitter.com/MytruQ",
        link_type: LinkType.TARGET
      }
    ]
  },
];

// COMPONENTS DATA

export const CompanyInformation = [
  {
    alt: "presentation-chart",
    src: chartSVG,
    header: "Digitize your hustle",
    text: "Log your trips, accept payment and build a verifiable earring history",
    textClassName: "company-info-text",
    iconStyle: "bg-orange",
  },
  {
    alt: "card",
    src: cardSVG,
    header: "Access financial support for your business",
    text: "Access financing solutions for all your operational expenses.",
    textClassName: "company-info-text",
    iconStyle: "bg-orange",
  },
  {
    alt: "moneys",
    src: moneysSVG,
    header: "Earn more money",
    text: "Boost your earnings and make more money by accepting trips from our business partners.",
    textClassName: "company-info-text",
    iconStyle: "bg-orange",
  },
  {
    alt: "people",
    src: peopleSVG,
    header: "Get empowered",
    text: "Connect with other operators, access training, tools, hangouts, and do business with community members.",
    textClassName: "company-info-text",
    iconStyle: "bg-orange",
  }
];

export const CoreBeliefs = [
  {
    alt: "",
    src: "",
    number: "1",
    text: "Inefficient logistics processes would always be difficult and expensive to manage or scale for any business",
    textClassName: "beliefs-text",
    iconStyle: "bg-light-orange text-orange",
  },
  {
    alt: "",
    src: "",
    number: "2",
    text: "Logistics success goes beyond just process optimization, it also heavily depends on the capability of the vehicle to be efficient",
    textClassName: "beliefs-text",
    iconStyle: "bg-orange",
  },
  {
    alt: "",
    src: "",
    number: "3",
    text: "1 and 2 above are best executed in a connected system with minimal friction and fragmentation.",
    textClassName: "beliefs-text",
    iconStyle: "bg-light-orange text-primary",
  },
];

export const DeliveryGoals = [
  {
    border: "bg-primary",
    title: "Activate tech-enabled logistics operations from start to finish",
    description: "Moving now or later?, it's your call—your move, your schedule. Schedule your move at your discretion with guaranteed service."
  },
  {
    border: "bg-warning",
    title: "Access a range of vehicle sizes for your deliveriesless Connection",
    description: "Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetur"
  },
  {
    border: "bg-orange",
    title: "Save costs with aggregated trips and flexible pricing",
    description: "Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetur"
  },
  {
    border: "bg-primary",
    title: "Gain real-time oversight and visibility into your deliveries",
    description: "Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetu...."
  },
];

export const LogisticsUpgrade = [
  {
    border: "bg-primary",
    title: "Digitize all your hustle",
    description: "Log your trips, buy insurance per trip, receive payments, share real-time status update link, record your sales, track your expenses, and Issue invoices."
  },
  {
    border: "bg-warning",
    title: "Access Financial support for business",
    description: "Access financing for all your operational expenses, fueling, repairs, spare parts etc. without the hassle of paying at once."
  },
  {
    border: "bg-orange",
    title: "Get discounts on your expenses",
    description: "Enjoy discounts at fuel stations, mechanic workshops, spare part stores etc when you patronize any of our partner merchants."
  },
  {
    border: "bg-primary",
    title: "Earn more money",
    description: "Reduce your vehicle idle periods, get real time connections with people and businesses who need your service to earn more money."
  },
  {
    border: "bg-warning",
    title: "Connect with your people",
    description: "Connect with other operators, access training, tools, hangouts, and do business with community members."
  },
];

export const LogisticsUpgradeOptions = [
  {
    option: "Drivers"
  },
  {
    option: "Vehicle Owner"
  },
  {
    option: "Operators"
  },
];

export const Logos = [
  { src: feature1, alt: 'Payaza' },
    { src: feature2, alt: 'Google' },
    { src: feature3, alt: 'Yalo' },
    { src: feature4, alt: 'Vfd' },
    { src: feature5, alt: 'Ulospaces' },
    { src: feature6, alt: 'Paystack' },
    { src: feature7, alt: 'ProvidusBank' },
    { src: feature8, alt: 'SeedFi' },
    { src: feature12, alt: 'lenco' },
    { src: feature9, alt: 'MyCover.ai' },
    { src: feature10, alt: 'DriveMe' },
    { src: feature11, alt: 'curacell' },
]

export const MovingExperience = [
  {
    border: "bg-primary",
    title: "Move at your convenience",
    description: "Moving now or later?, it's your call—your move, your schedule. Schedule your move at your discretion with guaranteed service."
  },
  {
    border: "bg-warning",
    title: "Seamless Connection",
    description: "Get connected with the closest verified and trusted driver on truQ with real-time status updates on your trip."
  },
  {
    border: "bg-orange",
    title: "With Peace of mind and top-Notch Care",
    description: "Secure your belongings with boxes, shrink wraps, and bubble wraps for utmost safety with an added layer of comprehensive insurance on your items from pickup to drop off."
  },
  {
    border: "bg-primary",
    title: "No Heavy Lifting",
    description: "Request loaders to handle all your heavy lifting needs with ease. Leave the hard work to our loaders—they'll handle it all"
  },
  {
    border: "bg-warning",
    title: "Post Move Services",
    description: "Beyond just moving, leverage our wide range of domestic service partners and partnership discounts to access cleaners, carpenters, electricians, plumbers etc to save you time and help you enjoy your new space"
  },
];

export const Products: IProduct[] = [
  {
    title: "TruQ App",
    key: "truq_app",
    description: `Powering ambitious moves for individuals and our partner's customer.`,
    details: [
      {listItem: "Log all your trips and collect payments"},
      {listItem: "Build earning history and access financing"},
      {listItem: "Enjoy discounts on operational expenses"},
      {listItem: "Access more trips to earn more money"},
    ],
    link: {
      text: "Learn More",
      href: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_APP}`,
      type: LinkType.IN_APP,
    }
  },
  {
    title: "Move by truQ",
    key: "move_by_truq",
    description: `Powering ambitious moves for individuals and our partner's customer.`,
    details: [
      {listItem: "Multiple sizes of vehicles with insurance cover for your properties"},
      {listItem: "Movers and packaging materials for seamless moves"},
      {listItem: "Domestic artisans for all your home service needs"},
      {listItem: "Offer logistics services to your customers & community members"},
    ],
    link: {
      text: "Learn More",
      href: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ}`,
      type: LinkType.IN_APP,
    }
  },
  {
    title: "Siju by truQ",
    key: "siju_by_truq",
    details: [
      {listItem: "Optimized delivery planning and aggregation"},
      {listItem: "Access different sizes of vehicles on drivehub"},
      {listItem: "Warehouse automation and inventory management"},
      {listItem: "Real-time delivery coordination, status update and reconciliation"},
    ],
    link: {
      text: "Learn More",
      href: `/${MenuType.PRODUCTS}/${SubMenuType.SIJU}`,
      type: LinkType.IN_APP,
    }
  },
];

export const Slides = [
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/aboutUs_people_4.jpeg",
    alt: "Slide 1",
  },
  {
    src: SliderImage3,
    alt: "Slide 2",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/aboutUs_people_3.jpeg",
    alt: "Slide 3",
  },
  {
    src: SliderImage3,
    alt: "Slide 4",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/aboutUs_people_1.jpeg",
    alt: "Slide 1",
  },
  {
    src: SliderImage3,
    alt: "Slide 2",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/career_truQstars_7.jpg",
    alt: "Slide 3",
  },
  {
    src: SliderImage3,
    alt: "Slide 4",
  },
]

export const Solutions = [
  {
    imgAlt: "truQDriverApp",
    imgSrc: solution1,
    name: "Drivehub by truQ",
    imgOrder: ColumnOrder.SECOND,
    spacing: "",
    details: [
      {
        subHeading: "i. Financing:",
        description: "Get your operational expenses sorted without the pressure of one off payment, everything from repairs, to spare parts, and insurance"
      },
      {
        subHeading: "ii. Microloans:",
        description: "Access quick loans for emergency needs and expenses"
      },
      {
        subHeading: "iii. Partial payment collection:",
        description: "Automate how you receive payments in full-pre trip, or in parts at pickup and drop off"
      },
      {
        subHeading: "iv. Fuel Card:",
        description: "Fuel fuel access fuel station with your special truQ card to enjoy fuel financing and discounts."
      },
      {
        subHeading: "v. Discounts:",
        description: "Get your operational needs serviced by our integrated partners to enjoy discounts and cashback on every transaction"
      },
      {
        subHeading: "vi. Early wage access:",
        description: "Get access to your earnings earlier than the payment cycle of any business you service"
      }
    ]
  },
  {
    imgAlt: "moveBytruQ",
    imgSrc: solution2,
    name: "Move by truQ",
    imgOrder: ColumnOrder.FIRST,
    spacing: "ms-md-5",
    details: [
      {
        subHeading: "i. Partial payment:",
        description: "Pay for your trip on your terms, in full-pre trip, or in parts at pickup and drop off"
      },
      {
        subHeading: "ii. Seamless Payment:",
        description: "Pay for your trip, additional moving services and domestic artisans without hassle"
      },
      {
        subHeading: "iii. truQ Points:",
        description: "Spend your truQ oints to access domestic artisans or on your next move"
      }
    ]
  },
  {
    imgAlt: "siju",
    imgSrc: solution3,
    name: "Siju by truQ",
    imgOrder: ColumnOrder.SECOND,
    spacing: "",
    details: [
      {
        subHeading: "i. Trip financing:",
        description: "Get your business deliveries done without the immediate cashflow and expense burden"
      },
      {
        subHeading: "ii. Wallet service:",
        description: "Pay for your trip, additional moving services and domestic artisans without hassle"
      },
      {
        subHeading: "iii. Wallet service:",
        description: "Leverage our wallet service to pay for your deliveries without the complication of bank transfers"
      }
    ]
  },
];

export const Stats = [
  {
    title: "3000+",
    description: "Drivers Running on truQ"
  },
  {
    title: "100k",
    description: "Trips Logged by Drivers"
  },
  {
    title: "$3M",
    description: "in Transaction Volume"
  },
  {
    title: "$1M",
    description: "In Financing and Discounts"
  },
];

export const Steps = [
  {
    number: "i",
    title: "Signup",
    description: "Provide simple personal details",
  },
  {
    number: "ii",
    title: "Upload Required Documents",
    description: "Get Approved. Take less than 24 hours to verify all your documentation",
  },
  {
    number: "iii",
    title: "Welcome to the level of your logistics operation",
    description: "Lorem ipsum dolor sit amet consectetur. Pellentesque quis nulla tempor a sit odio.",
  },
];

export const HomeTestimonies = [
  {
    content: "I referred my sister's husband and friends on the driver app and i got paid for that. I'm still looking for more drivers.",
    image: null,
    author: "Adaeze Ofor",
    medium: "",
    imageBg: "bg-info"
  },
  {
    content: "Their services are awesome. From asking about what your needs are to seeing through to making sure that you are satisfied. The driver I got attached to was so nice and highly professional. I did not regret using their service.",
    image: null,
    author: "Morenike R. Daniel",
    medium: "google reviews",
    imageBg: "bg-success"
  },
  {
    content: "Its Amazing that truQ could pull this off! Super impressed, no other solution for the market like this. Well Done guys.",
    image: null,
    author: "Cynthia Okeke",
    medium: "",
    imageBg: "bg-secondary"
  }
]

export const TruQAppTestimonies = [
  {
    content: "Working as a business driver via truQ opened doors. the wallet automation simplifies payment.",
    image: null,
    author: "Femi Olakunle",
    medium: "",
    imageBg: "bg-info"
  },
  {
    content: "truQ just makes it easy to make more money. Even with my korope (Small van), i still make enough.",
    image: null,
    author: "David Kizito",
    medium: "",
    imageBg: "bg-success"
  },
  {
    content: "The New Points system just makes sense, extra money for completing more trips even after getting paid.",
    image: null,
    author: "Uche Okeke",
    medium: "",
    imageBg: "bg-secondary"
  }
]

export const MoveTestimonies = [
  {
    content: "This was my first time using a moving service and Truq came through pretty amazing and smooth service. Customer service was superb. Great job and service @Morenike really amazing 👏 👌 👍.",
    image: null,
    author: "NWABUAKU OKAFOR",
    medium: "google reviews",
    imageBg: "bg-info"
  },
  {
    content: "I'll use truQ service over and over again if I have to. I was worried at first, but they handled the whole process with ease. Customer relations from the drivers to the customer care services are top-notch.",
    image: null,
    author: "Mide isaac",
    medium: "google reviews",
    imageBg: "bg-success"
  },
  {
    content: "I enjoyed the customer service of truQ. They were very helpful and patient. It felt like I was talking to a friend. The driver was also very polite. Kudos to truQ!.",
    image: null,
    author: "Omo Imade",
    medium: "google reviews",
    imageBg: "bg-secondary"
  }
]

export const SijuTestimonies = [
  {
    content: "I used to wake up very early by manually matching drivers to trips, but with Siju, the whole process is automated and easy!",
    image: null,
    author: "Kingsley Chukwu - 3PL Manager, Trade Depot",
    medium: "",
    imageBg: "bg-info"
  },
  {
    content: "Siju makes our logistics optimization process very easy, all we have to do is upload our manifest and boom! Aggregated and distributed evenly across board.",
    image: null,
    author: "Mr Kunle",
    medium: "",
    imageBg: "bg-success"
  },
  {
    content: "Its Amazing that truQ could pull this off! Super impressed, no other solution for the market like this. Well Done guys.",
    image: null,
    author: "Cynthia Okeke",
    medium: "",
    imageBg: "bg-secondary"
  }
]

export const UpgradeOptions = [
  {
    option: "Book your move"
  },
  {
    option: "Connect instantly with the nearest driver"
  },
  {
    option: "Driver arrives promptly as scheduled"
  },
  {
    option: "Driver gets paid upon delivery completion"
  },
  {
    option: "Receive real time updates on your move"
  },
];
