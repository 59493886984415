import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";

import Tabs from "../../components/fragment/Tabs";
import {CompanyInformation, HomeTestimonies, Products} from "../../utils/mapper/Mapper";
import {AppTheme, MenuType, SubMenuType} from "../../modules/enums";
import {useTheme} from "../../components/hooks/useTheme";
import Testimony from "../../components/fragment/Testimony";
import PageLayout from "../../components/layouts/PageLayout";
import AppStoreBanner from "../../components/fragment/AppStoreBanner";
import CircleThumbnailWithIcons from "../../components/fragment/CircleThumbnails";
import { SourceType } from "../../modules/enums";
import Brands from "../../components/fragment/Brands";
import driveHubProductIMG from "../../assets/images/pngs/driveHub.png";
import moveByTruQProductIMG from "../../assets/images/pngs/move.png";
import sijuProductIMG from "../../assets/images/pngs/siju.png";
import Image from "../../components/fragment/Image";
import SuccessSVG from "../../assets/images/svgs/Successful.svg";
import CoinsSVG from "../../assets/images/svgs/coins.svg";
import DarkButton from "../../components/buttons/DarkButton";
import GetStartedButton from "../../components/buttons/GetStartedButton";

const Home = () => {
  const appTheme = useTheme(), navigate = useNavigate();
  const [activeTabKey, setActiveTabKey] = useState(Products[0].key);

  const getImageForActiveTab = (key: string) => {
    switch (key) {
      case "move_by_truq":
        return moveByTruQProductIMG;
      case "siju_by_truq":
        return sijuProductIMG;
      default:
        return driveHubProductIMG;
    }
  };

  useEffect(() => {
    if (appTheme.theme === AppTheme.LIGHT) appTheme.toggleTheme(AppTheme.DARK);
  }, [appTheme.theme]);

  return (
    <PageLayout headerTheme={AppTheme.DARK} footerTheme={AppTheme.DARK}>
      <section>
        <div className="hero-content home-hero">
          <h1 className="mt-md-70">
            Supercharge Your Logistics <span className="text-orange">Hustle!</span>
          </h1>
          <p className="hero-description">
          Digitize your trips, access financing for your operations, enjoy discounts on your everyday expenses, and connect with people that need your vehicle.
          </p>
          <div className="d-flex gap-4 mb-5 mt-3">
            <GetStartedButton/>
            <DarkButton
              btn={{
                text: "Learn More",
                btnClassName: 'btn-block px-4 px-md-5 border border-white',
                clickFn: () => navigate(`/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_APP}`)
              }}
            />
          </div>
        </div>
      </section>

      <Brands/>

      <main className="light-theme pb-5 d-flex justify-content-center flex-column">

        <Container>
          <h3 className="text-darker text-center mt-5 mt-md-70 w-720 mx-auto">We are empowering commercial logistics fleet operators.</h3>
          <p className="text-medium text-center mt-3 mt-md-2 mb-4">
            Providing everything you need to be efficient to yourselves and the market you service.
          </p>
          <CircleThumbnailWithIcons companyInfo={CompanyInformation} source={SourceType.HOME}/>
          <Row className="my-5 my-md-100 mb-md-5">
            <Col md={6} className="h-auto">
              <h2 className="text-center text-md-start">Our Products</h2>
              <p className="text-medium mt-2 mb-3 text-center text-md-start">
                Explore our ecosystem of solutions, powering smarter, faster, and more efficient logistics operations
                for everyone.
              </p>
              <Tabs data={Products} setActiveTabKey={setActiveTabKey}/>
            </Col>
            <Col md={6} className={`h-auto ${activeTabKey} my-4 mt-md-0 curved-images`}>
              { activeTabKey === "truq_app" &&
                <>
                  <div className="success-message">
                    <div className="px-2">
                      <p>Your cash has been Transferred</p>
                      <p className="success-text">Successfully</p>
                    </div>
                    <Image className="mx-2" alt="successful" src={SuccessSVG} width={55} height={55}/>
                  </div>
                  <div className="points-notification">
                    <div className="px-2">
                      <p>You have earned</p>
                      <p className="points-text">200 Points</p>
                    </div>
                    <Image alt="coins" src={CoinsSVG} width={30} height={35} className="ms-4 me-2"/>
                  </div>
                </>
              }
              <Image src={getImageForActiveTab(activeTabKey)} alt="Product Image" />
            </Col>
          </Row>
        </Container>
        <Testimony testimonyData={HomeTestimonies}/>
        <AppStoreBanner/>
      </main>
    </PageLayout>
  );
}

export default Home;
