import {Fragment, useEffect, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import {Image} from "react-bootstrap";

import NavItem from "./NavItem";
import MobileHeader from "./MobileHeader";
import {useTheme} from "../../hooks/useTheme";
import {useMobileNav} from "../../hooks/useMobileNav";
import MenuSVG from "../../../assets/images/svgs/menu.svg";
import truQLogo from "../../../assets/images/pngs/truq-logo.png";
import ArrowUpSVG from "../../../assets/images/svgs/arrow-up.svg";
import MenuWhiteSVG from "../../../assets/images/svgs/menu-white.svg";
import ArrowDownSVG from "../../../assets/images/svgs/arrow-down.svg";
import {AppTheme, MenuType, NavItemType} from "../../../modules/enums";
import ArrowUpWhiteSVG from "../../../assets/images/svgs/arrow-up-white.svg";
import truQLogoWhite from "../../../assets/images/pngs/truq-logo-white.png";
import ArrowDownWhiteSVG from "../../../assets/images/svgs/arrow-down-white.svg";
import {CompanyData, NavItems, ProductsData, ResourcesData} from "../../../utils/mapper/Mapper";
import GetStartedButton from "../../buttons/GetStartedButton";


const Header = ({theme = AppTheme.LIGHT}: { theme?: AppTheme }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const appTheme = useTheme(), navMode = useMobileNav()
  const [activeNavItem, setActiveNavItem] = useState<NavItemType | null>(null);
  const darkMode = appTheme.theme === AppTheme.DARK || theme === AppTheme.DARK;
  const itemData = !activeNavItem ? null : activeNavItem === NavItemType.PRODUCTS ? ProductsData : activeNavItem === NavItemType.COMPANY ? CompanyData : ResourcesData;
 
  // Close the dropdown menu when clicked outside or widow changes
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setActiveNavItem(null);
      }
    };

    // Close the dropdown when switching browser windows
    const handleWindowBlur = () => setActiveNavItem(null);

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('blur', handleWindowBlur);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('blur', handleWindowBlur);
    };
    
  }, []);


  return (
    <Fragment>
      <header className={`fixed-top ${darkMode ? "bg-dark" : "bg-white"}`}>
        <nav>
          <NavLink to={`/${MenuType.HOME}`} className="my-auto">
            {darkMode ?
              <Image src={truQLogoWhite} alt="truQ Logo" width={60} height={25}/>
              : <Image src={truQLogo} alt="truQ Logo" width={60} height={25}/>
            }
          </NavLink>
          <ul className="nav col-12 col-lg-auto mx-lg-auto  justify-content-center font-size-14 gap-2 gap-md-5 mb-md-0">
            {NavItems.map(item =>
              <li key={item.type} onMouseEnter={() => setActiveNavItem(item.type)}>
                <button className={`nav-link px-2 ${darkMode ? "text-white" : ""}`}>{item.name}
                  {activeNavItem === item.type ? <Image
                      className="ms-2"
                      alt="ARROW_DOWN"
                      src={darkMode ? ArrowUpWhiteSVG : ArrowUpSVG}/>
                    : <Image
                      className="ms-2"
                      alt="ARROW_DOWN"
                      src={darkMode ? ArrowDownWhiteSVG : ArrowDownSVG}/>
                  }
                </button>
              </li>
            )}
          </ul>

          <div className="header-actions gap-4">
            {/* <PrimaryButton
              btn={{
                text: "Get Started",
                btnClassName: 'btn-block',
                clickFn: () => navigate(`/${MenuType.AUTHENTICATION}/${SubMenuType.GET_STARTED}`, {state: getState(lastSegment)})
              }}
            /> */}
            <GetStartedButton/>
          </div>

          {!navMode.navMenu &&
						<Image className="menu" src={darkMode ? MenuWhiteSVG : MenuSVG} alt="menu" width={24} height={24}
									 onClick={() => navMode.toggleNavMenu(true)}/>}
        </nav>

        {itemData &&
					<div ref={dropdownRef} className={`header-item-container mx-auto ${activeNavItem ? "show" : "hide"}`}
							 onMouseLeave={() => setActiveNavItem(null)}>
            {itemData.map((data, index) =>
              <NavItem key={index} {...data} className={darkMode ? "text-white" : ""}
              />
            )}
					</div>
        }
      </header>

      <MobileHeader isDarkMode={darkMode}/>
    </Fragment>
  );
}

export default Header;
