import { Fragment, useState } from "react";
// import { useDispatch } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { Line } from "rc-progress";
// import toast from "react-hot-toast";

// import UserSVG from "../../assets/images/svgs/user.svg";
// import MerchantSVG from "../../assets/images/svgs/shop.svg";
// import CookieHelper from "../../utils/helpers/cookieHelper";

import AuthLayout from "../../components/layouts/AuthLayout";
// import BackSVG from "../../assets/images/svgs/arrow-left.svg";
// import { AppKeys, Loading } from "../../utils/helpers/constants";
import PasswordInput from "../../components/fragment/PasswordInput";
// import ProfileService from "../../utils/services/profile.service";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { emailValidator } from "../../utils/services/validator.service";
// import { useRegisterProfileMutation } from "../../store/slices/api.slice";
// import { getProfile, onAuthentication } from "../../store/slices/auth.slice";
import { formatWebsite, queryErrorHandler } from "../../utils/helpers/logicHelper";
import { InputValidator } from "../../modules/enums";
// import { InputValidator, SubMenuType, MenuType, AccountType } from "../../modules/enums";
import BackSVG from "../../assets/images/svgs/left-arrow.svg";
import ArrowDownSVG from"../../assets/images/svgs/arrow-down.svg";
import SuccessSVG from "../../assets/images/svgs/Successful.svg";
import closeSVG from "../../assets/images/svgs/close-circle.svg";
import Image from "../../components/fragment/Image";

const Register = () => {
  const navigate = useNavigate(), location = useLocation();
  const [step, setStep] = useState(1);
//   const [terms, setTerms] = useState(false);
  const [website, setWebsite] = useState("");
  const [fullName, setFullName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [loadState, setLoadState] = useState(false);
  const [country, setCountry] = useState("");
  const [primaryStateOfOperations, setPrimaryStateOfOperations] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState({ value: "", errorState: false });
  const [password, setPassword] = useState<{ errorState: boolean, value: string | undefined }>({ value: undefined, errorState: false });
  const [confirmPassword, setConfirmPassword] = useState<{ errorState: boolean, value: string | undefined }>({ value: undefined, errorState: false });
//   const [registerProfile, { isLoading: loading }] = useRegisterProfileMutation();

  const isInvalid = !fullName  || !email.value || email.errorState
    || emailValidator(email.value).validState === InputValidator.INVALID || !password.value || !confirmPassword.value || password.errorState;

  const isBusinessInvalid = !businessName || !website;

  const goBack = () => {
    // if (location.state && location.state === "CREATE_PROFILE") 
      navigate(-1);
    // else setStep(step - 1);
  }

//   const lineProgressStatus = () => {
//     if (step === 3) return 100;
//     else if (step === 2) return 60;
//     else return 30;
//   };

  const handleFieldValidation = (data: string, type: string) => {
    if (type === "EMAIL") {
      const validatedEmail = emailValidator(data);
      setEmail(prev => ({ ...prev, value: validatedEmail.validEmail }));
      const isEmailValid = validatedEmail.validState !== InputValidator.VALID;
      setEmail(prev => ({ ...prev, errorState: isEmailValid }));
    }
  };

//   const authenticateUser = async () => {
//     const payload = {
//       first_name: firstName, last_name: lastName,
//       email: email.value, password: password.value
//     };

//     let result = await registerProfile(payload);
//     if (result.data && result.data.status === Loading.SUCCESS) {
//       onAuthentication(dispatch, result.data.data.access);
//       toast.success("Your profile has been created successfully");
//       setStep(step + 1);
//     } else {
//       const errorMessage = queryErrorHandler("register", result);
//       toast.error(errorMessage);
//     }
//   };

//   const handleOnboarding = async () => {
//     setLoadState(true);
//     if (step === 2) {
//       if (accountType === "MERCHANT") setStep(step + 1);
//       else if (accountType === "CUSTOMER") navigate("/");
//     } else {
//       let payload = {
//         name: businessName, url: formatWebsite(website), is_merchant: true
//       };
//       const { data: responseData } = await ProfileService.createBusinessProfile(payload);
//       if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
//       else {
//         toast.success(responseData.message);
//         await getProfile(dispatch);
//         CookieHelper.set(AppKeys.USER_DOM, responseData.data, AppKeys.COOKIE_EXPIRY_PERIOD);
//         if (location.state && location.state === "CREATE_PROFILE") navigate(-1);
//         else navigate('/');
//       }
//     }
//     setLoadState(false);
//   }
const getHeader = (key: string) => {
  switch (key) {
    case "truq_app":
      return "on TruQ App";
    case "siju":
      return "on Siju";
    case "partner":
      return "as a Partner";
    default:
      return "on Move";
  }
};

const getLink = (key: string) => {
  switch (key) {
    case "truq_app":
      return "https://dodge-super-app.vercel.app/auth/login";
    case "siju":
      return "https://app.siju.it/auth/login";
    case "partner":
      return "https://dodge-super-app.vercel.app/auth/login";
    default:
      return "https://app.mytruq.com/auth/login";
  }
};

  return (
    <AuthLayout step={step}>
      <Fragment>
      { step === 1 && (
        <Fragment>
        <div className="d-flex flex-row mb-4 cursor-pointer" onClick={goBack}>
            <Image src={BackSVG} alt="GO_BACK" className="mx-2 text-black"/>
            <p className="text-black">Back</p>
        </div>
        <h4 className="text-start mt-1 mt-lg-5 text-black">
            Get Started {getHeader(location.state)}
        </h4>
        <p className="text-office-brown-700 mb-3 text-start">
            Kindly provide the necessary details
        </p>
            <div className="form-floating my-4">
              <input
                type="text"
                className="form-control form-lightblue"
                value={fullName}
                placeholder="full_name"
                onChange={(e) => setFullName(e.target.value)}
              />
              <label>Full Name</label>
            </div>
            { (location.state === "siju" || location.state === "partner") && <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control form-lightblue"
                value={businessName}
                placeholder="business_name"
                onChange={(e) => setBusinessName(e.target.value)}
              />
              <label>Business Name</label>
            </div>}
            {
             <div className="d-flex flex-column">
                <div className={`form-floating mb-4 ${(location.state === "move" || location.state === "truq_app") ? "order-3" : "order-1"}`}>
                  <input
                    type="text"
                    className="form-control form-lightblue"
                    value={country}
                    placeholder="country"
                    onChange={(e) => setCountry(e.target.value)}
                  />
                  <label>Country</label>
                  <Image src={ArrowDownSVG} alt="drop_down" className="form-icon" width={20} height={20}/>
                </div>
              <div className={`form-floating mb-4 ${(location.state === "move" || location.state === "truq_app") ? "order-4" : "order-2"}`}>
              <input
                type="text"
                className="form-control form-lightblue"
                value={primaryStateOfOperations}
                placeholder="primary_state_of_Operations"
                onChange={(e) => setPrimaryStateOfOperations(e.target.value)}
              />
              <label>Primary State of Operation</label>
              <Image src={ArrowDownSVG} alt="drop_down" className="form-icon" width={20} height={20}/>
              </div>
              <div className={`form-floating mb-4 ${(location.state === "move" || location.state === "truq_app") ? "order-1" : "order-3"}`}>
              <input
                type="text" placeholder="email address" value={email.value}
                className={`form-control form-lightblue ${email.errorState && "is-invalid"}`}
                onChange={(e) => handleFieldValidation(e.target.value, "EMAIL")} />
              <label>{location.state === "move"  ? "" : "Work"} Email Address</label>
              </div>
              <div className={`form-floating mb-4 ${(location.state === "move" || location.state === "truq_app") ? "order-2" : "order-4"}`}>
              <input
                type="text"
                className="form-control form-lightblue"
                value={phoneNumber}
                placeholder="phone_number"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <label>Phone Number</label>
              </div>
              </div>
            }
            <PasswordInput name="password" value={password.value} isValidatorRequired
              setErrorState={value => setPassword(prev => ({ ...prev, errorState: value }))}
              setInput={value => setPassword({ errorState: password.errorState, value })} />
            <PasswordInput name="confirmPassword" value={password.value} isValidatorRequired
              setErrorState={value => setConfirmPassword(prev => ({ ...prev, errorState: value }))}
              setInput={value => setConfirmPassword({ errorState: password.errorState, value })} />
            {/* <div className="ms-1 d-inline-flex column-gap-3">
              <input className="form-check-input" type="checkbox" id="terms" defaultChecked={terms} onChange={(e) => setTerms(e.target.checked)}
              />
              <small>
                By activating your account, you agree to our{" "}
                <a className="text-primary" href="https://www.truq.it/legal/terms-conditions" target="_blank" rel="noreferrer">
                  Terms and Conditions.
                </a>
              </small>
            </div> */}
            <PrimaryButton 
              btn={{
                text: 'Save & Continue',
                //loadState: loading,
                // disabledState: isInvalid,
                btnClassName: 'btn-block my-4 fw-bold w-100',
                clickFn: () => setStep(2)
              }}/>

            <div className="d-flex justify-content-center">
              <div className="mt-2 p-3 bg-primary-light">
                <p className="text-office-brown-800 text-center">
                  Already have an account?
                  <NavLink to={getLink(location.state)} className="text-primary-emphasis ms-2 fw-bold"  target="_blank">
                    Sign in
                  </NavLink>
                </p>
              </div>
            </div>
        </Fragment>
      )}

      {step === 2 && (
       <Fragment>
            <div className="my-5 success-card">
              <p onClick={goBack} className="py-4 cursor-pointer"><Image src={closeSVG} alt="close" className="me-2"/>Close</p>
              <Image src={SuccessSVG} alt="successful" width={200} height={180} className="mx-auto mb-3"/>
              <h4 className="text-success-dark text-center py-3">Email has been Sent !</h4>
              <p className="text-medium text-center font-size-14">A whole new world of logistics possibilities awaits!</p>
              <PrimaryButton 
                btn={{
                  text: 'Check your Email for next steps!',
                  //loadState: loadState,
                  disabledState: isBusinessInvalid,
                  btnClassName: 'btn-block my-5 mx-auto',
                  //clickFn: handleOnboarding
                }}
              />
            </div>
          </Fragment>
      )}
      </Fragment>
    </AuthLayout>
  );
};

export default Register;