import Slider from "react-slick";
import Image from "./Image";
import { ITestimonyData } from "../../modules/components";


type Props = {
  testimonyData: ITestimonyData[],
};

const Testimony = ({ testimonyData }: Props) =>{ 
  
  const ReactSlickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 5,
    cssease: "ease",
    pauseOnHover: true,
    className: "center",
    centerMode: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        }
      }
    ]
  }

  return(
  <section className="d-flex flex-column">
    <div className="bg-light d-flex flex-column py-40">
      <div className="d-flex flex-column align-self-center mt-3">
        <h3 className="text-darker text-center">Don't take our word for it</h3>
        <p className="text-medium text-center mt-1 mb-2">
          Check out what our Partners are saying about us
        </p>
      </div>
      <div className="my-3 my-lg-5">
        <Slider {...ReactSlickSettings}>
          {testimonyData.map((data, index) =>
            <div key={index} className="px-2 d-flex justify-content-center">
              <div className="card h-100 w-300">
                <div className="card-body">
                  <span className="testimony-text">{data.content}</span>
                  <div className="d-flex gap-3 mt-3">
                    {data.image
                      ? <Image
                        alt="lazy"
                        src={data.image}
                        className="rounded-circle"
                        width="40" height="40"
                      />
                      : <div style={{ width: "40px", height: "40px"}} 
                        className={`d-flex justify-content-center align-items-center rounded-circle ${data.imageBg}`}>
                            {data.author[0].toUpperCase()}
                        </div>
                    }
                    
                    <div className="my-1">
                      <p className="font-size-14 fw-bold">{data.author}</p>
                      <small className="font-size-12">{data.medium}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Slider>
      </div>
    </div>
  </section>
);}

export default Testimony;