import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AppTheme } from "../../modules/enums";
import Image from "../../components/fragment/Image";
import Brands from "../../components/fragment/Brands";
import { useTheme } from "../../components/hooks/useTheme";
import PageLayout from "../../components/layouts/PageLayout";
import Testimony from "../../components/fragment/Testimony";
import AppStoreBanner from "../../components/fragment/AppStoreBanner";
import { DeliveryGoals, MovingExperience, SijuTestimonies } from "../../utils/mapper/Mapper";
import CollapsibleText from "../../components/fragment/CollapsibleText";
import PrimaryButton from "../../components/buttons/PrimaryButton";

const Siju = () => {
  const appTheme = useTheme();

  useEffect(() => {
    if (appTheme.theme === AppTheme.DARK) appTheme.toggleTheme(AppTheme.LIGHT);
  }, []);

  const handleClick = () => window.open("https://app.siju.it/auth/register")
  return (
    <PageLayout>
      <section className="siju-hero">
        <Container className="d-flex flex-column flex-wrap">
          <div className="text-center w-720 align-self-center">
            <h1>Driving Efficiency, Fueling Growth.</h1>
            <p className="hero-description">
              Streamline your logistics operations for seamless efficiency and
              accelerated growth.
            </p>
          </div>
          <PrimaryButton
            btn={{
              text: "Sign Up for your business",
              btnClassName: "my-3 mx-auto",
              clickFn: handleClick
            }}
          />

          <div className="w-100 w-500 align-self-center">
            <Image
              alt="HERO"
              src="https://storage.googleapis.com/public_storage_truq/website/product_siju.png"
              className="mt-5"
            />
          </div>
        </Container>
      </section>

      <Brands />

      <Container className="mt-4 mt-md-70">
        <section className="px-4 my-5 d-flex flex-column">
          <h4 className="text-orange text-center w-771 w-100 align-self-center mb-4 mb-lg-5">
            Explore the game changing operating system designed specifically for
            third party logistics. Effortlessly plan and aggregate deliveries with
            ease
          </h4>
          <Row>
          <Col xs={12} className="mx-auto" style={{ maxWidth: "1000px"}}>
            <Image
              alt="lazy"
              src="https://storage.googleapis.com/public_storage_truq/website/siju_warehouse_workers.jpg"
              className="rounded-4 image-fluid"
            />
          </Col>
        
          <Col xs={12} className="d-flex flex-column w-600 w-100 mx-auto">
            <p className="font-size-18 text-center text-dark my-4">
              Join the league of ambitious businesses trusting truQ to tackle
              their logistics hurdles, expedite deliveries, and drive their
              enterprises forward.
            </p>
            <PrimaryButton
              btn={{
                text: "Sign Up Now",
                btnClassName: "px-5 mx-auto",
                clickFn: handleClick
              }}
            />
          </Col>
          </Row>
        </section>

        <section className="mt-4 mt-md-70 d-flex flex-column">
          <h2 className="text-darker w-720 w-100 text-center align-self-center">
            Here's how Siju makes your delivery goals achievable
          </h2>
          <Row className="my-4 my-lg-5">
            <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 0 }}>
              <CollapsibleText collapsibleTextData={DeliveryGoals} />
            </Col>
            <Col xs={{ span: 12, order: 0 }} lg={{ span: 6, order: 1 }} className="m-auto">
              <Image
                alt="lazy"
                src="https://storage.googleapis.com/public_storage_truq/website/siju_employees_checking_cargo.jpg"
                 className="w-100 rounded-4" />
            </Col>
          </Row>
        </section>

        <section className="my-5 mt-md-70 d-flex flex-column">
          <h2 className="text-darker w-720 w-100 text-center align-self-center">
            Discover a Fast, Smart and Easy Moving Experience With truQ
          </h2>
          <Row className="my-4 my-lg-5">
            <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 0 }}>
              <CollapsibleText collapsibleTextData={MovingExperience} />
            </Col>
            <Col xs={{ span: 12, order: 0 }} lg={{ span: 6, order: 1 }} className="m-auto">
              <Image
                alt="lazy"
                src="https://storage.googleapis.com/public_storage_truq/website/siju_couples_with_boxes.jpg"
                className="w-100 rounded-4"
              />
            </Col>
          </Row>
        </section>
      </Container>

      <Testimony  testimonyData={SijuTestimonies}/>
      <AppStoreBanner />
    </PageLayout>
  );
}

export default Siju;