//ALPHABETICAL ORDER
export enum AppTheme {
  DARK = "DARK",
  LIGHT = "LIGHT"
};

export enum ColumnOrder {
  FIRST = "FIRST",
  SECOND = "SECOND",
  THIRD = "THIRD",
};

export enum ErrorNavType {
  G0_BACK = "G0_BACK",
  HOME = "HOME"
}

export enum LEGALS {
  PRIVACY_POLICY = "PRIVACY_POLICY",
  TERMS_CONDITION = "TERMS_CONDITION",
  TERMS_DRIVER_PATNER = "TERMS_DRIVER_PATNER",
};

export enum LinkType {
  EMAIL = "EMAIL",
  TARGET = "TARGET",
  IN_APP = "IN_APP",
};

export enum MenuType {
  HOME = "home",
  LEGAL = "legal",
  COMPANY = "company",
  PRODUCTS = "products",
  RESOURCES = "resources",
  UNAUTHORISED = "unauthorised",
  CONSTRUCTION = "under-construction",
  AUTHENTICATION = "authentication",
};

export enum NavItemType {
  PRODUCTS = "PRODUCTS",
  COMPANY = "COMPANY",
  RESOURCES = "RESOURCES"
}

export enum PolicyLinkPlacement {
  MORE = "MORE",
  TITLE = "TITLE",
  FOOTER = "FOOTER",
  SUBTITLE = "SUBTITLE",
  DESCRIPTION = "DESCRIPTION",
};

export enum SubMenuType {
  SIJU = "siju-by-truq",
  TRUQ_APP = "truq-app",
  TRUQ_PAY = "truq-pay",
  TRUQ = "move-by-truq",
  PRIVACY_POLICY = "privacy-policy",
  TERMS_CONDITION = "terms-conditions",
  TERMS_DRIVER_PATNER = "terms-driver-partner",
  CAREER = "career",
  ABOUT_US = "about-us",
  CONTACT_US = "contact-us",
  GET_STARTED = "get-started",
  FREQUENTLY_ASKED_QUESTIONS = "frequently-asked-questions"
};

export enum SourceType {
  HOME = 'HOME',
  ABOUT_US = 'ABOUT_US',
};

export enum InputValidator {
  INVALID = "INVALID",
  VALID = "VALID"
};

export enum InputEnum {
	BUTTON = "button",
	CHECKBOX = "checkbox",
	COLOR = "color",
	DATE = "date",
	DATETIME = "datetime-local",
	DECIMAL = "decimal",
	NONE = "none",
	NUMBER = "number",
	EMAIL = "email",
	FILE = "file",
	HIDDEN = "hidden",
	IMAGE = "image",
	MONTH = "month",
	NUMERIC = "numeric",
	PASSWORD = "password",
	RADIO = "radio",
	RANGE = "range",
	RESET = "reset",
	SEARCH = "search",
	SUBMIT = "submit",
	TEL = "tel",
	TEXT = "text",
	TIME = "time",
	URL = "url",
	WEEK = "week"
}