import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "./PrimaryButton";
import { MenuType, SubMenuType } from "../../modules/enums";


const GetStartedButton = () => {
    const location = useLocation(), navigate = useNavigate();
  const [lastSegment, setLastSegment] = useState('');
  
    useEffect(() => {
      getPath();
    }, [location]);
  
    const getPath = () => {
      const pathSegments = location.pathname.split('/').filter(Boolean);
      const lastSegment = pathSegments[pathSegments.length - 1];
      setLastSegment(lastSegment);
    };
  
    const getState= (key: string) => {
      switch (key) {
        case "truq-app":
          return "truq_app";
        case "siju-by-truq":
          return "siju";
        case "home":
          return "partner"
        default:
          return "move";
      }
    };
  

    return(
        <PrimaryButton
            btn={{
                text: 'Get Started',
                btnClassName: 'btn-block px-4',
                clickFn: () => navigate(`/${MenuType.AUTHENTICATION}/${SubMenuType.GET_STARTED}`, {state: getState(lastSegment)})
            }}
        />
    )
}

export default GetStartedButton;