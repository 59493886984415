import { Fragment, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb} from "react-bootstrap";
import PageLayout from "../layouts/PageLayout";
import { PolicyLinkPlacement, LinkType, LEGALS, MenuType, SubMenuType } from "../../modules/enums";
import {IPolicy, IPolicyExtra, IPolicyLink} from "../../modules/components";

type Props = {
  type: LEGALS,
  description: string | string[],
  policies: IPolicy[],
  footer?: { title: string, subTitle?: string}
}

const Policy = ({ type, description, policies, footer }: Props) => {
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  useCallback(() => { }, [itemRefs])

  const links = (link: IPolicyLink) => (
    <a href={link.type === LinkType.EMAIL ? `mailto${link.href}` : link.href}>
      {link.href}
    </a>
  );

  const policiesOrderedList = (extras: IPolicyExtra[]) => {
    return (
      <ol className="my-3">
        {extras.map((item, itemIndex) =>
          <li key={itemIndex} className="mb-2">
            <h6>
              {item?.title} {(item.link && item.link.placement === PolicyLinkPlacement.TITLE) &&
                <a href={item.link.type === LinkType.EMAIL ? `mailto${item.link.href}` : item.link.href}>
                  {item.link.href}
                </a>
              }
            </h6>
            <span>
              <strong>{item?.subTitle}{" "}</strong>{item?.description}{" "}
              {(item.link && item.link.placement === PolicyLinkPlacement.DESCRIPTION) &&
                <a href={item.link.type === LinkType.EMAIL ? `mailto${item.link.href}` : item.link.href}>
                  {item.link.href}
                </a>
              }
            </span>
            <span>
              {(item && item.extra) &&
                <ol type="i" className="my-3">
                  {item.extra.map((extra, extraIndex) =>
                    <li key={extraIndex} className="my-1">
                      {extra}{" "}{((item.extra?.length === extraIndex + 1) && item.link && item.link.placement === PolicyLinkPlacement.MORE) &&
                        <a href={item.link.type === LinkType.EMAIL ? `mailto${item.link.href}` : item.link.href}>
                          {item.link.href}
                        </a>
                      }
                    </li>
                  )}
                </ol>
              }
            </span>
            <span>
              {(item.footer && typeof item.footer === "string")
                ? <p className="mt-2">
                  {item.footer}{" "}
                  {(item.link && item.link.placement === PolicyLinkPlacement.FOOTER) && links(item.link)}
                </p>
                : <Fragment>
                  {Array.isArray(item.footer) && item.footer.map((footer, footerIndex) =>
                    <p key={footerIndex} className="mt-3">
                      {footer}{" "}{(item.link && item.link.placement === PolicyLinkPlacement.FOOTER) &&
                        <a href={item.link.type === LinkType.EMAIL ? `mailto${item.link.href}` : item.link.href}>
                          {item.link.href}
                        </a>
                      }
                    </p>
                  )}
                </Fragment>
              }
            </span>
          </li>
        )}
      </ol>
    )
  };

  const scrollToItem = (index: number) => {
    const item = itemRefs.current[index];

    if (item) {
      item.scrollIntoView({ behavior: 'smooth',block: "center"});
      const paddingTop = 20;
      item.scrollTop = item.offsetTop - paddingTop;
    }
  };

  return (
    <PageLayout>
      <section className="main-wrapper">
        <main className="main-content no-scrollbar" >
          <Breadcrumb className="mx-3 mt-3 mb-5 mx-lg-5">
            <Breadcrumb.Item active={(type && type === LEGALS.PRIVACY_POLICY)}>
              <Link to={`/${MenuType.LEGAL}/${SubMenuType.PRIVACY_POLICY}`}>
                Privacy Policy
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active={(type && type === LEGALS.TERMS_CONDITION)}>
              <Link to={`/${MenuType.LEGAL}/${SubMenuType.TERMS_CONDITION}`}>
                Terms & Condition
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active={(type && type === LEGALS.TERMS_DRIVER_PATNER)}>
              <Link to={`/${MenuType.LEGAL}/${SubMenuType.TERMS_DRIVER_PATNER}`}>
                Parterns & Drivers Terms of Service
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="text-medium m-3 m-lg-5">
            <p className="text-break text-justify">{description}</p>
            <div>
              {policies.map((policy, policyIndex) =>
                <div id={`${policyIndex}`} className="mt-5" key={policyIndex} ref={(el) => (itemRefs.current[policyIndex] = el)}>
                  <h5 className="text-dark">
                    {policy.title}{" "}
                    {(policy.link && policy.link.placement === PolicyLinkPlacement.TITLE) && links(policy.link)}
                  </h5>
                  {policy.subTitle && <h6 className="mt-3">
                    {policy.subTitle}{" "}
                    {(policy.link && policy.link.placement === PolicyLinkPlacement.SUBTITLE) && links(policy.link)}
                  </h6>
                  }
                  {policy.description &&
                    <Fragment>
                      {(policy.description && typeof policy.description === "string")
                        ? <p className="mt-2">
                          {policy.description}{" "}
                          {(policy.link && policy.link.placement === PolicyLinkPlacement.DESCRIPTION) && links(policy.link)}
                        </p>
                        : <Fragment>
                          {Array.isArray(policy.description) && policy.description.map((policyDescription, descriptionIndex) =>
                            <p key={descriptionIndex} className="mt-2">
                              {policyDescription}
                              {policy.description}{" "}{(policy.link && policy.link.placement === PolicyLinkPlacement.DESCRIPTION) &&
                                <a href={policy.link.type === LinkType.EMAIL ? `mailto${policy.link.href}` : policy.link.href}>
                                  {policy.link.href}
                                </a>
                              }
                            </p>
                          )}
                        </Fragment>
                      }
                    </Fragment>
                  }
                  {policy.extras && policiesOrderedList(policy.extras)}

                  <p className="mt-3">
                    {policy?.footer}{" "}
                    {(policy.link && policy.link.placement === PolicyLinkPlacement.FOOTER) && links(policy.link)}
                  </p>
                </div>
              )}
              {(footer && footer.title) && <h5 className="mt-5">{footer.title}</h5>}
              {(footer && footer.subTitle) && <h6 className="mt-3"> {footer.subTitle}</h6>}
            </div>
          </div>
        </main>
        <aside className="side-content no-scrollbar">
          <h5 className="mb-2">On this page</h5>
          {policies.map((policy, policyIndex) => <p key={policyIndex} onClick={() => scrollToItem(policyIndex)}
            className="my-3 text-primary cursor-pointer " style={{ fontWeight: "550" }}>
            {policy.title}
          </p>
          )}
        </aside>
      </section>
    </PageLayout>
  );
}

export default Policy;
