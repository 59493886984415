import { IButtons } from "../../modules/components";
import { Spinner } from "react-bootstrap";


const WhiteButton = ({ btn }: { btn: IButtons }) => {
  
  console.log(btn.disabledState);
  
  return(
  <button onClick={btn.clickFn} disabled={btn.disabledState}
    className="btn-subscribe">
    {btn.image && <img src={btn.image.imgSrc} alt={btn.image.imgAlt ?? "ICON"}
      className={`${btn.image.imgClass ? btn.image.imgClass : 'me-1'}`} width={btn.image.width ?? 24} height={btn.image.height ?? 24} />
    }
    {btn.text}
    {btn.loadState && <Spinner animation="border" size="sm" className="ms-2" color="black"/>}
  </button>
);}

export default WhiteButton;