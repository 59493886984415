import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";

import Image from "../../components/fragment/Image";
import { useTheme } from "../../components/hooks/useTheme";
import Shake from "../../assets/images/pngs/handshake.png";
import VisionSvg from "../../assets/images/svgs/vsion.svg";
import PageLayout from "../../components/layouts/PageLayout";
import MissionSvg from "../../assets/images/svgs/mission.svg";
import { AppTheme, MenuType, SubMenuType } from "../../modules/enums";
import { CoreBeliefs } from "../../utils/mapper/Mapper";
import CircleThumbnailWithIcons from "../../components/fragment/CircleThumbnails";
import { SourceType } from "../../modules/enums";
import blueArchSvg from "../../assets/images/svgs/blue-arch.svg";
import { Logos } from "../../utils/mapper/Mapper";
import PrimaryButton from "../../components/buttons/PrimaryButton";


const AboutUs = () => {
  const navigate = useNavigate(), appTheme = useTheme();

  useEffect(() => {
    if (appTheme.theme === AppTheme.LIGHT) appTheme.toggleTheme(AppTheme.DARK);
  }, []);

  const firstRowLogos = Logos.slice(0, 6);
  const secondRowLogos = Logos.slice(6, 10);
  const thirdRowLogos = Logos.slice(10);

  return (
    <PageLayout headerTheme={AppTheme.DARK} footerTheme={AppTheme.DARK}>
      <Container className="my-5 mt-md-70">
        <section className="grid-white mt-5 px-md-4 px-2">
          <h2 className="text-center align-self-center pt-4 pt-md-0">
            We are a one-stop shop for commercial fleet operators and businesses, providing solutions that make their third-party logistics interactions smarter, faster and more efficient
          </h2>
          <Image
            alt="HERO"
            src="https://storage.googleapis.com/public_storage_truq/website/aboutUs_delivery_vans.jpg"
            className="mx-auto my-5 rounded-4"
          />
        </section>
        <section className="mx-3 px-md-5 mt-5 mt-md-130">
          <h3 className="text-center align-self-center mt-0">truQ is built on 3 <span className="text-orange">Core Beliefs</span></h3>
          <Image alt="lazy" src={blueArchSvg} className="blue-arch mb-md-4"/>
          <CircleThumbnailWithIcons companyInfo={CoreBeliefs} source={SourceType.ABOUT_US} />
        </section>

        <section className="px-4 py-5 mt-5 mt-md-130">
            <div className="d-flex flex-column flex-md-row justify-content-center row-gap-4 column-gap-3 ">
                <Card className="bg-lighter-orange w-100" style={{maxWidth: "450px", height: "350px"}}>
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Image src={MissionSvg} alt="mission" className="icon-avatar-mission bg-primary-blue" />
                    <div>
                      <h4 className="card-title ms-0 fw-bold w-50">Our Mission</h4>
                      <p className="text-medium font-size-16">To empower commercial fleet operators with everything they need to operate a thriving business and find fulfilment in their hustle.</p>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="bg-light-blue w-100" style={{maxWidth: "450px", height: "350px"}}>
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Image src={VisionSvg} alt="vision" className="icon-avatar-vision bg-lighter-yellow" />
                    <div>
                      <h4 className="card-title ms-0 fw-bold">Our Vision</h4>
                      <p className="text-medium font-size-16">We envision a pan-African future where every logistics-related economic and commercial activity is faster, smarter, and better than ever because of the work we do at truQ</p>
                    </div>
                  </Card.Body>
                </Card>
            </div>
        </section>

        <section className="text-center mt-5 mt-md-130">
          <h2 className="mb-2">
            Our People <Image src={Shake} alt="handshake" width={50} height={50} className="ms-2" />
          </h2>
          <p className="text-center w-720 mx-auto mb-4 px-2">truQstars are the secret sauce behind the magic we cook at truQ, different people, expertise and background but are aligned in vision for what’s possible for Africa from our kitchen at truQ</p>
          <PrimaryButton
            btn={{
              text: "Discover Open Position",
              btnClassName: 'mx-auto mb-5',
              clickFn: () => navigate(`/${MenuType.COMPANY}/${SubMenuType.CAREER}`),
            }}
          />
            <Row className="py-2 w-100 mx-auto">
              <Col md={6} className="mb-3">
              <div className="people-1"/>
              </Col>
              <Col md={6} className="mb-3">
              <div className="people-2"/>
              </Col>
              <Col md={4} className="mb-3">
                <div className="people-3"/>
              </Col>
              <Col md={3} className="mb-3">
                <div className="people-4"/>
              </Col>
              <Col md={5} className="mb-3">
              <div className="people-5"/>
              </Col>
            </Row>
        </section>

        <section className="text-center mt-5 mt-md-70">
          <h5 className="mb-3">Featured in the News</h5>
          <div className="py-2 w-100 mx-auto w-1000">
          <Row className="custom-row-6 d-md-flex mx-auto">
        {firstRowLogos.map((logo) => (
          <Col xs={12} sm={6} lg={2} className="text-center mb-3">
            <Image src={logo.src} alt={logo.alt} height={60} className="px-1"/>
          </Col>
        ))}
      </Row>

      {/* Second Row: 4 Logos */}
      <Row className="custom-row-4 d-md-flex mx-auto">
        {secondRowLogos.map((logo) => (
          <Col xs={12} sm={6} lg={3} className="text-center mb-3">
            <Image src={logo.src} alt={logo.alt} height={90} className="px-3"/>
          </Col>
        ))}
      </Row>

      {/* Third Row: 2 Logos */}
      <Row className="custom-row-2 d-md-flex mx-auto">
        {thirdRowLogos.map((logo) => (
          <Col xs={12} sm={6} lg={6} className="text-center mb-3">
            <Image src={logo.src} alt={logo.alt} height={80}/>
          </Col>
        ))}
      </Row>
          </div>
        </section>
      </Container>

    </PageLayout>
  );
}

export default AboutUs;