import { useEffect } from "react";
import {Card, Container } from "react-bootstrap";
import { AppTheme, MenuType } from "../../modules/enums";
import Image from "../../components/fragment/Image";
import AppStore from "../../components/buttons/AppStore";
import PlayStore from "../../components/buttons/PlayStore";
import { useTheme } from "../../components/hooks/useTheme";
import PageLayout from "../../components/layouts/PageLayout";
import Testimonies from "../../components/fragment/Testimony";
import CompanyStat from "../../components/fragment/CompanyStat";
import AppStoreBanner from "../../components/fragment/AppStoreBanner";
import tripDetail from "../../assets/images/svgs/tripDetails.svg";
import quoteDetails from "../../assets/images/svgs/quoteDetails.svg";
import success from "../../assets/images/svgs/Successful.svg";
import balanceIllustration from "../../assets/images/svgs/balance_illustration.svg";
import withdrawalIllustration from "../../assets/images/svgs/withdrawal_illustration.svg"; 
import discountIllustration from "../../assets/images/svgs/discount_illustration.svg";
import { Steps, TruQAppTestimonies } from "../../utils/mapper/Mapper";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/PrimaryButton";



type StepProps = {
  number: string;
  title: string;
  description: string;
};

const Step: React.FC<StepProps> = ({ number, title, description }) => (
  <div className="step">
    <div className="mt-2"><h6 className="step-number">{number}</h6></div>
    <div>
      <h5 className="mb-2 mx-0">{title}</h5>
      <p className="text-medium font-size-14">{description}</p>
    </div>
  </div>
);

const Drivehub = () => {
  const appTheme = useTheme(), navigate = useNavigate();

  useEffect(() => {
    if (appTheme.theme === AppTheme.DARK) appTheme.toggleTheme(AppTheme.LIGHT);
  }, []);

  const handleClick = () => navigate(`/${MenuType.CONSTRUCTION}`);
  
  return (
    <PageLayout>
      <section className="mb-0 driveHub-hero">
        <Container className="d-flex flex-column flex-wrap text-center">
          <div className="text-center">
            <h1>Get Access To <span className="text-orange">The Community</span> <br />
              You Need To Grow Your Logistics Hustle.</h1>
            <div className="d-flex flex-lg-row flex-wrap align-items-center justify-content-center gap-3 gap-md-4 my-5">
              <AppStore />
              <PrimaryButton
               btn={{
                text: "Signup as a Driver",
                clickFn: handleClick
              }}
              />
              <PlayStore />
            </div>
          </div>

          <div className="w-100 w-500 align-self-center">
            <Image
              alt="HERO"
              src="https://storage.googleapis.com/public_storage_truq/website/product_drivehub.png"
              className="mt-5 image-responsive" height={450}
            />
          </div>
        </Container>
      </section>

      <CompanyStat />

      <Container>
        <section className="text-center" style={{ margin: "100px 0" }}>
          <div className="align-self-center">
            <h2 className="section-title">Empower your drive with truQ</h2>
            <p className="section-description">Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div className="d-flex flex-wrap gap-4 gap-lg-3 justify-content-center mt-5">
            <Card className="empower-cards empower-card-1">
              <Card.Body>
                <div className="bg-white w-50 rounded-4 p-3 text-start">
                  <p>Trip Details</p>
                  <Image src={tripDetail} alt="trip_details" />
                </div>
                <div className="bg-white w-170 p-3 d-flex flex-column align-items-start rounded-4 ms-240">
                  <p className="mb-2">Quote Details</p>
                  <Image src={quoteDetails} alt="quote_details" className="w-100 h-100"/>
                </div>
              </Card.Body>
              <Card.Footer style={{ backgroundColor: "inherit", borderTop: "0" }}>
                <h4 className="text-primary mt-2">Digitize all your hustle</h4>
                <p className="text-medium font-size-16 px-lg-5 mt-2 lh-sm">Log your trips,buy insurance per trip, receive payments, share real-time status update link, record your sales, track your expenses,and Issue invoices</p>
              </Card.Footer>
            </Card>
            <Card className="empower-cards empower-card-1">
              <Card.Body>
                <Image src={withdrawalIllustration} alt="illustration" className="h-40" />
                <div className="bg-white w-150 rounded-4 my-2 p-3">
                  <Image src={success} alt="success" />
                  <p className="text-medium font-size-9 lh-sm">Your cash has been Withdrawn</p>
                  <p className="text-success fw-bold font-size-12">Successfully</p>
                </div>
              </Card.Body>
              <Card.Footer style={{ backgroundColor: "inherit", borderTop: "0" }}>
                <h4 className="text-primary">Earn more money</h4>
                <p className="text-medium font-size-16 px-lg-5 mt-2 lh-sm">Reduce your vehicle idle periods, get real time connections with people and businesses who need your service to earn more money.</p>
              </Card.Footer>
            </Card>
            <Card className="empower-cards empower-card-2">
              <Card.Body>
                <div className="bg-white w-250 rounded-4 py-3 py-md-4 px-md-3 my-md-3 float-start">
                  <p className="pb-2">Wallet Balance</p>
                  <Image src={balanceIllustration} alt="balance_illustration" />
                </div>
                <div className="bg-light-yellow w-250 rounded-2 d-flex justify-content-between mt--30 float-end me-2">
                  <div className="my-auto text-start ms-3">
                    <p className="font-size-9">Your cash has been Transfered</p>
                    <p className="font-size-12 fw-bold">Successfully</p>
                  </div>
                  <Image src={success} alt="success" className="me-md-3 me-2 h-40" />
                </div>
              </Card.Body>
              <Card.Footer style={{ backgroundColor: "inherit", borderTop: "0" }}>
                <h4 className="text-primary">Access financial support for your business</h4>
                <p className="text-medium font-size-16 px-lg-5 mt-2 lh-sm">Log your trips,buy insurance per trip, receive payments, share real-time status update link, record your sales, track your expenses,and Issue invoices</p>
              </Card.Footer>
            </Card>
            <Card className="empower-cards empower-card-3">
              <Card.Body>
                <div className="bg-white w-75 rounded-4 mt-md-4 p-3 mx-auto">
                  <div className="image-frame">
                    <div className="circle">
                      <Image src="https://storage.googleapis.com/public_storage_truq/website/drivehub_connect_people_1.jpeg" alt="Person 1" />
                    </div>
                    <div className="circle">
                      <Image src="https://storage.googleapis.com/public_storage_truq/website/drivehub_connect_people_2.jpeg" alt="Person 2" />
                    </div>
                    <div className="circle">
                      <Image src="https://storage.googleapis.com/public_storage_truq/website/drivehub_connect_people_3.jpeg" alt="Person 3" />
                    </div>
                    <div className="circle">
                      <Image src="https://storage.googleapis.com/public_storage_truq/website/drivehub_connect_people_4.jpeg" alt="Person 4" />
                    </div>
                    <div className="circle">
                      <Image src="https://storage.googleapis.com/public_storage_truq/website/drivehub_connect_people_5.jpeg" alt="Person 5" />
                    </div>
                    <div className="circle plus">
                      <span>+</span>
                    </div>
                  </div>
                  <p className="font-size-12 text-medium lh-sm mt-2">Connect with other drivers on the community</p>
                </div>
              </Card.Body>
              <Card.Footer style={{ backgroundColor: "inherit", borderTop: "0" }}>
                <h4 className="text-primary">Connect with your people</h4>
                <p className="text-medium font-size-16 px-lg-5 mt-2 lh-sm">Connect with other operators, access training, tools, hangouts, and do business with community members.</p>
              </Card.Footer>
            </Card>
            <Card className="empower-cards empower-card-2">
              <Card.Body>
                <div className="bg-white w-250 rounded-4 mt-md-4 p-3">
                  <p>Discount Code</p>
                  <Image src={discountIllustration} alt="discount_illustration" />
                </div>
                <div className="bg-light-yellow w-250 rounded-2 ms-5  py-2 d-inline-flex">
                  <div className="my-auto text-start ms-3">
                    <p className="font-size-9">Discount Code has been applied</p>
                    <p className="font-size-12 fw-bold">Successfully</p>
                  </div>
                  <Image src={success} alt="success" className="me-md-3 h-40" />
                </div>
              </Card.Body>
              <Card.Footer style={{ backgroundColor: "inherit", borderTop: "0", maxWidth: "500px" }}>
                <h4 className="text-primary">Get discounts on your expenses</h4>
                <p className="text-medium font-size-16 px-lg-5 mt-2 lh-sm">Enjoy discounts at fuel stations, mechanic workshops, spare part stores etc when you patronize any of our</p>
              </Card.Footer>
            </Card>
          </div>
        </section>

        <section className="text-center mt-md-130 mb-5 mb-md-70">
          <h2 className="section-title mx-auto">How to get started</h2>
          <p className="section-description">Lorem ipsum dolor sit amet consectetur.</p>
          <PrimaryButton
               btn={{
                text: "Get Started on Drivehub",
                btnClassName: "my-3 align-self-center",
                clickFn: handleClick
              }}
              />
          <div className="steps-container">
            <div className="">
              <Image src="https://storage.googleapis.com/public_storage_truq/website/drivehub_delivery_man.jpg"
              alt="Getting started illustration"
              className="w-500 w-100 rounded-4" />
            </div>
            <div className="ms-lg-5 mb-4">
              {Steps.map((step, index) => (
                <Step key={index} number={step.number} title={step.title} description={step.description} />
              ))}
            </div>
          </div>
        </section>
      </Container>
      <Testimonies testimonyData={TruQAppTestimonies}/>
      <AppStoreBanner />
    </PageLayout>
  );
}

export default Drivehub;