import { useState } from "react";
import { Collapse } from "react-bootstrap";
import {ICollapsibleTextData} from "../../modules/components";

type Props = {
  collapsibleTextData: ICollapsibleTextData[],
}

const CollapsibleText = ({ collapsibleTextData }: Props) => {
  const [open, setOpen] = useState<{ [key: number]: boolean }>({ 0: true });

  const handleToggle = (index: number) => {
    setOpen((prevState) => ({[index]: !prevState[index]}));
  };


  return (
    <div className="w-lg-45-p w-100">
      <div className="collabsible-text-container mt-4 mt-md-0">
        {collapsibleTextData.map((item, index) =>
          <div className="d-flex gap-4 my-3" key={index}>
            <div className={`accordion-border-start ${item.border ? item.border : ""}`} />
            <div className="d-flex flex-column px-3 cursor-pointer">
              <h5 className="text-darker font-size-23 ms-0 my-auto" onClick={() => handleToggle(index)} aria-controls={`example-collapse-text-${index}`} aria-expanded={open[index] || false}>
                {item?.title}
              </h5>
              <Collapse in={open[index]}>
                <p className="text-medium mt-2" id="example-collapse-text">{item?.description}</p>
              </Collapse>
            </div>
          </div>
        )}
      </div>

    </div>
  )
}

export default CollapsibleText
