import { useState, useEffect, ChangeEvent } from "react";
import toast from "react-hot-toast";
import { useTheme } from "../../components/hooks/useTheme";
import PageLayout from "../../components/layouts/PageLayout"
import { AppTheme } from "../../modules/enums";
import { Col, Container, Row } from "react-bootstrap";
import Phone from "../../assets/images/pngs/telephone.png";
import Image from "../../components/fragment/Image";
import APIService from "../../utils/services/api.service";
import { Loading } from "../../utils/services/constants";
import { emailValidator, phoneValidator } from "../../utils/services/validator.service";
import PrimaryButton from "../../components/buttons/PrimaryButton";


const ContactUs = () => {
  const appTheme = useTheme();
  const [contactFormData, setContactFormData] = useState({name: '', email: '', number: '', message: ''});
  const [contactFormErrors, setContactFormErrors] = useState({name: false, email: false, number: false, message: false});
  const [contactFormErrorMessages, setContactFormErrorMessages] = useState({name: "", email: "", number: "", message: ""});
  const [loading, setLoading] = useState(false);
  const isInvalid = !contactFormData.name || !contactFormData.email || !contactFormData.number || !contactFormData.message || contactFormErrors.name || contactFormErrors.email || contactFormErrors.number || contactFormErrors.message;

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const {name, value} =  e.target;
        const newValue = name === "email" ? value.toLowerCase() : value;
        setContactFormData({...contactFormData, [name]: newValue});
        validate(name, newValue);
    }

    const validate = (name: string, value: string): void => {
        const error = {...contactFormErrors};
        const errorMgs = {...contactFormErrorMessages};
    
        if (name === "name") {
          if (!value || value.length < 1) {
            error.name = true;
            errorMgs.name = "Name is Required";
          }
          else {
            error.name = false;
            errorMgs.name = "";
          }
        } else if (name === "email"){
           if (!emailValidator(contactFormData.email)) {
            error.email = true;
            errorMgs.email = "Invalid email address";
          }
          else {
            error.email = false;
            errorMgs.email = "";
          }
        }else if(name === "number"){
           if (!phoneValidator(contactFormData.number)){
            error.number = true;
            errorMgs.number = "Invalid phone number"
          }
          else {
            error.number = false;
            errorMgs.number = "";
          }
        }else {
          if (!value || value.length < 1) {
            error.message = true;
            errorMgs.message = "Message is Required";
          }
          else {
            error.message = false;
            errorMgs.message = "";
          }
        }
      
        setContactFormErrors(error);
        setContactFormErrorMessages(errorMgs);
    }

    const contactUs = async () => {
      setLoading(true)
      const payload = {
        name: contactFormData.name, 
        email: contactFormData.email, 
        phone: contactFormData.number, 
        content: contactFormData.message
      }
      const {data: responseData} = await APIService.sendMessage(payload);
      if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
      else {
            toast.success(responseData.message); 
            setContactFormData({name: '', email: '', number: '', message: ''});
          }
       setLoading(false)
    }

  useEffect(() => {
    if (appTheme.theme === AppTheme.DARK) appTheme.toggleTheme(AppTheme.LIGHT);
  }, [appTheme.theme]);

  return (
    <PageLayout >
      <Container className="mt-5 mt-md-70">
        <Row className="row justify-content-center align-self-center my-5">
          <Col lg={6} className="text-start ms-4 ms-lg-0">
            <h5 className="text-darker font-size-30 font-weight-700 mt-2 ms-0 mb-3">Contact Us <Image src={Phone} alt="telephone" width={30} height={24} className="ms-2" /></h5>
            <p className="text-medium mb-4 font-size-16 font-weight-400">We are passionate executors but even better listeners!</p>
            <h5 className="text-darker font-size-20 font-weight-700 ms-0 mb-2">Address</h5>
            <p className="text-medium mb-4 font-size-16 font-weight-400">truQ, Zone tech park, Gbagada</p>
            <h5 className="text-darker font-size-20 font-weight-700 ms-0 mb-2">Phone Number</h5>
            <a href="tel:+2348168735873" className="text-medium mb-4 font-size-16 font-weight-400">090345321223</a>
            <h5 className="text-darker font-size-20 font-weight-700 ms-0 mb-2 mt-4">Email Address</h5>
            <a href="mailto:contact@mytruq.com" className="text-medium font-size-16 font-weight-400">contact@mytruq.com</a>
          </Col>
          <Col lg={6} className="mt-5 mt-lg-0">
            <div className="card">
              <div className="card-body">
                <div className="row mt-2 px-lg-4">
                  <p className="font-weight-700 font-size-18 text-dark-theme mb-4">Talk to us right here and we will get back to you immediately:</p>
                  <div className="col-12 mb-4">
                    <div className="form-floating">
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control form-lightblue"
                        onChange={(e) => handleChange(e)} 
                        value={contactFormData.name}
                        name="name"
                      />
                      <label>Name</label>
                    </div>
                    {(contactFormErrors.name && contactFormErrorMessages.name) && <small className='text-danger mt-4'>{contactFormErrorMessages.name}</small>}
                  </div>
                  <div className="col-12 mb-4">
                    <div className="form-floating">
                      <input
                        type="text"
                        placeholder="Email Address"
                        className="form-control form-lightblue"
                        onChange={(e) => handleChange(e)} 
                        value={contactFormData.email}
                        name="email"
                      />
                      <label>Email Address</label>
                    </div>
                    {(contactFormErrors.email && contactFormErrorMessages.email) && <small className='text-danger mt-4'>{contactFormErrorMessages.email}</small>}
                  </div>
                  <div className="col-12 mb-4">
                    <div className="form-floating">
                      <input
                        type="tel"
                        placeholder="Phone"
                        className={`form-control form-lightblue`}
                        onChange={(e) => handleChange(e)} 
                        value={contactFormData.number}
                        name="number"
                      />
                      <label>Phone Number</label>
                    </div>
                    {(contactFormErrors.number && contactFormErrorMessages.number) && <small className='text-danger mt-4'>{contactFormErrorMessages.number}</small>}
                  </div>
                  <div className="col-12 mb-4">
                    <div className="form-floating">
                      <textarea
                        placeholder="Message"
                        className="form-control form-lightblue textarea"
                        maxLength={200}
                        onChange={(e) => handleChange(e)} 
                        value={contactFormData.message}
                        name="message"
                      />
                      <label>Message</label>
                    </div>
                    {(contactFormErrors.message && contactFormErrorMessages.message) && <small className='text-danger mt-4'>{contactFormErrorMessages.message}</small>}
                  </div>
                  <div className="col-12 mb-3">
                    <PrimaryButton
                      btn={{
                        text: "Send",
                        btnClassName: 'font-size-16 w-100',
                        disabledState: isInvalid,
                        loadState: loading,
                        clickFn: contactUs,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  )
}

export default ContactUs