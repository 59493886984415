import { useState, useEffect } from "react";
import {Scrollama, Step} from "react-scrollama";

type StackComponentProps = {
  scrollPosition: number;
}  

const StackComponent: React.FC<StackComponentProps> = ({ scrollPosition }) => {
    const [currentNumber, setCurrentNumber] = useState(10);


  useEffect(() => {
    const sectionHeight = window.innerHeight; 
    const currentSection = Math.floor(scrollPosition / sectionHeight);

    if (currentSection === 0) {
      setCurrentNumber(10);
    } else if (currentSection === 1) {
      setCurrentNumber(20);
    } else if (currentSection === 2) {
      setCurrentNumber(30);
    } else if (currentSection === 3) {
      setCurrentNumber(40);
    }
  }, [scrollPosition]);


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 overflow-auto vh-100 no-scrollbar">
          <Scrollama onStepEnter={({ data }) => setCurrentNumber(data)}>
            <Step data={10}>
              <div className="stepi bg-light d-flex align-items-center justify-content-center">
                Section 1 (10)
              </div>
            </Step>
            <Step data={20}>
              <div className="stepi bg-light d-flex align-items-center justify-content-center">
                Section 2 (20)
              </div>
            </Step>
            <Step data={30}>
              <div className="stepi bg-light d-flex align-items-center justify-content-center">
                Section 3 (30)
              </div>
            </Step>
            <Step data={40}>
              <div className="stepi bg-light d-flex align-items-center justify-content-center">
                Section 4 (40)
              </div>
            </Step>
          </Scrollama>
        </div>

        <div className="col-md-6 d-flex align-items-center justify-content-center vh-100 sticky-top bg-secondary text-white">
          <div className="number-display">
            {currentNumber}
          </div>
        </div>
      </div>
    </div>

  )
}

export default StackComponent