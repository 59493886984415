import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import Image from "../../components/fragment/Image";
import StarSVG from "../../assets/images/svgs/star.svg";
import CrownSVG from "../../assets/images/svgs/crown.svg";
import { useTheme } from "../../components/hooks/useTheme";
import TruQStars from "../../components/fragment/TruQStars";
import PageLayout from "../../components/layouts/PageLayout";
import WhiteStarSVG from "../../assets/images/svgs/white-star.svg";
import YellowArchSVG from "../../assets/images/svgs/yellow-arch.svg";
import { AppTheme, MenuType } from "../../modules/enums";
import PrimaryButton from "../../components/buttons/PrimaryButton";
// import YellowArrowSVG from "../../assets/images/svgs/yellow-arrow.svg";


const Career = () => {
  const appTheme = useTheme();
  const navigate = useNavigate()
  useEffect(() => {
    if (appTheme.theme === AppTheme.LIGHT) appTheme.toggleTheme(AppTheme.DARK);
  }, [appTheme.theme]);

  return (
    <PageLayout headerTheme={AppTheme.DARK} footerTheme={AppTheme.DARK}>

      <section className="grid-white mt-md-70 my-5">
        <Container className="d-flex flex-column ">
          <div className="d-flex align-self-center pt-5 pt-md-4 px-2 px-md-0">
            <div>
              <h2 className="mt-3 text-center text-md-start">
                <Image
                  alt="CROWN"
                  src={CrownSVG}
                  className="align-self-start crown-position"
                />We are truQstars! 🚀💫</h2>
              <div className="d-flex justify-content-center align-items-center mt-2 w-500">
                <p className="text-center">
                  We are obsessed with doing hard things as long as it affects the progress of Africa!
                </p>
              </div>
            </div>
          </div>
          <Image
            alt="STAR"
            src={StarSVG}
            className="align-self-start mt-5 mt-md-0"
          />
          <div className="mx-auto mt-md-3 mb-md-80" style={{maxWidth: "620px"}}>
            <Image
              alt="HERO"
              src="https://storage.googleapis.com/public_storage_truq/website/career_truQstars_1.jpg"
              className="rounded-4 image-fluid"
            />
          </div>
          <Image
            alt="STAR"
            src={WhiteStarSVG}
            className="align-self-end my-md-4"
            width={24}
          />
        </Container>
      </section>

      <main className="career-article">
        <section className="ms-lg-90 my-5 mt-md-70">
          <h2>What makes us — us</h2>
          <Image
            alt="lazy"
            src={YellowArchSVG}
            className="ms-5"
          />
        </section>

        <TruQStars />

        <Container>
          <Row className="mb-2 mb-md-0">
            <Col lg={7} className="mt-md-130 mt-5">
              <Image
                alt="CULTURE_2"
                src="https://storage.googleapis.com/public_storage_truq/website/home_partner_training.jpeg"
                className="rounded-4"
              />
            </Col>
            <Col lg={5} className="mb-5 my-md-auto ps-md-5 text-start">
              <h4 className="mt-3 mt-md-130 text-center text-md-start">Project You</h4>
              <p className="font-size-18 mt-2 mb-3">
                At truQ, we recognize that your ability to offer value is
                limited by your current capacity. That's why we're dedicated
                to fostering your personal growth. We believe that as you
                continue to grow, your capacity will expand, enabling you to
                achieve even more
              </p>
            </Col>
          </Row>

          <section>
            <div className="d-flex flex-column mx-auto w-771 mt-5">
              <h3 className="align-self-center mt-md-130">We Work Together</h3>
              <p className="font-size-18 text-start text-md-center mb-5 mt-2 px-md-5">
                One will chase one thousand, two will chase ten thousand - Now
                imagine the collective power of the entire team- what the whole
                team can achieve working together, the result we can deliver and
                possibilities we can bring to life.
              </p>
            </div>
            <div className="d-flex justify-content-center gap-3 px-md-5 mt-5 mb-5 mb-md-0">
              <div style={{maxWidth:"280px", maxHeight:"380px", marginBottom: "-60px"}}>
                <Image
                  alt="WORK_1"
                  src="https://storage.googleapis.com/public_storage_truq/website/aboutUs_people_3.jpeg"
                  className="image-fluid  rounded-2"
                />
              </div>
              <div style={{maxWidth:"280px", maxHeight:"380px", marginTop: "-40px"}}>
                <Image
                  alt="WORK_2"
                  src="https://storage.googleapis.com/public_storage_truq/website/career_truQstars_7.jpg"
                  className="image-fluid rounded-2"
                />
              </div>
              <div style={{maxWidth:"280px", maxHeight:"380px", marginBottom: "-60px"}}>
                <Image
                  alt="WORK_2"
                  src="https://storage.googleapis.com/public_storage_truq/website/aboutUs_people_4.jpeg"
                  className="image-fluid rounded-2"
                />
              </div>
            </div>
          </section>

          <Row className="mt-5">
            <Col lg={5} className="mt-md-70 mt-5 text-start">
              <div className="my-0 my-lg-5 mt-md-70">
                <h4>Everyone is Welcomed Here !</h4>
                <p className="font-size-18 text-lg-start my-2">
                  Segregation and discrimination are not welcomed here, in
                  fact we build our strength around our differences
                </p>
              </div>
              <div className="mt-md-70 my-4 my-md-5">
                <h4>truQsters Are Lovers!</h4>
                <p className="font-size-18 text-lg-start my-2">
                  We love what we do, who we do it with and who we do it for
                </p>
              </div>
            </Col>
            <Col lg={7} className="mt-md-70">
              <Image
                alt="CULTURE"
                className="w-100 mt-md-70"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ed5f0d1600d299e1277e72eeeda18450c5e9f6c41b66082f0f68ba00ccdbc7c?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed5f0d1600d299e1277e72eeeda18450c5e9f6c41b66082f0f68ba00ccdbc7c?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed5f0d1600d299e1277e72eeeda18450c5e9f6c41b66082f0f68ba00ccdbc7c?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed5f0d1600d299e1277e72eeeda18450c5e9f6c41b66082f0f68ba00ccdbc7c?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed5f0d1600d299e1277e72eeeda18450c5e9f6c41b66082f0f68ba00ccdbc7c?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed5f0d1600d299e1277e72eeeda18450c5e9f6c41b66082f0f68ba00ccdbc7c?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed5f0d1600d299e1277e72eeeda18450c5e9f6c41b66082f0f68ba00ccdbc7c?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1ed5f0d1600d299e1277e72eeeda18450c5e9f6c41b66082f0f68ba00ccdbc7c?apiKey=7ed81b7744384cc0909bd2ca97455c68&"
              />
              <div className="mt-4 mt-lg-130 text-start">
                <h4>We Are Different </h4>
                <p className="font-size-18 text-lg-start mt-2 mt-md-2">
                  truQstars are non-conformists; we don't go with the flow, we set
                  the bar. We do things differently and better
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <section className="culture-info">
          <div className="container p-0 p-md-5">
            <div className="row d-flex justify-content-center gap-5">
              <div className="col-lg-5">
                <Image
                  alt="CULTURE_3"
                  className="w-100"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/acb819717df9a78eb25927397f8d12a1b86d11e2be42829d63859615819c1306?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/acb819717df9a78eb25927397f8d12a1b86d11e2be42829d63859615819c1306?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/acb819717df9a78eb25927397f8d12a1b86d11e2be42829d63859615819c1306?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/acb819717df9a78eb25927397f8d12a1b86d11e2be42829d63859615819c1306?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/acb819717df9a78eb25927397f8d12a1b86d11e2be42829d63859615819c1306?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/acb819717df9a78eb25927397f8d12a1b86d11e2be42829d63859615819c1306?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/acb819717df9a78eb25927397f8d12a1b86d11e2be42829d63859615819c1306?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/acb819717df9a78eb25927397f8d12a1b86d11e2be42829d63859615819c1306?apiKey=7ed81b7744384cc0909bd2ca97455c68&"
                />
                <div className="mt-md-5 mt-4 text-start">
                  <h5 className="line-spacing-05 text-dark">Result Over Activities</h5>
                  <p className="text-medium text-start mt-2">
                    It's either you are delivering the results or not,
                    activities count for nothing! We do whatever it takes to
                    get the desired result, period
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <Image
                  alt="CULTURE_4"
                  className="w-100"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/4a22f018abe7096eb8380e6f8a5f2e0aa4537ac8ea1d870a384be568a6a8f1b2?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a22f018abe7096eb8380e6f8a5f2e0aa4537ac8ea1d870a384be568a6a8f1b2?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a22f018abe7096eb8380e6f8a5f2e0aa4537ac8ea1d870a384be568a6a8f1b2?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a22f018abe7096eb8380e6f8a5f2e0aa4537ac8ea1d870a384be568a6a8f1b2?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a22f018abe7096eb8380e6f8a5f2e0aa4537ac8ea1d870a384be568a6a8f1b2?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a22f018abe7096eb8380e6f8a5f2e0aa4537ac8ea1d870a384be568a6a8f1b2?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a22f018abe7096eb8380e6f8a5f2e0aa4537ac8ea1d870a384be568a6a8f1b2?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4a22f018abe7096eb8380e6f8a5f2e0aa4537ac8ea1d870a384be568a6a8f1b2?apiKey=7ed81b7744384cc0909bd2ca97455c68&"
                />
                <div className="mt-md-5 mt-4 text-start">
                  <h5 className="line-spacing-05 text-dark">We Are Customer Obsessed </h5>
                  <p className="text-medium text-start mt-2">
                    We are unapologetically obsessed with our customers, doing
                    all we can at every point to ensure their needs are met
                    with the best experience possible
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Container>
          <section className="my-5 mt-md-130 p-3 p-md-0">
            <div className="newsletter px-3 px-md-0">
              <div className="w-507 w-100 py-4 py-lg-1">
                <h4 className="text-center">Join the truQStars</h4>
                <p className="font-size-14 text-center mt-1 mb-3 px-5 max-w-320 mx-auto">
                  Be a part of a team that is driving the future of logistics in Africa
                </p>
                <div className="d-flex justify-content-center mt-4">
                  <PrimaryButton
                    btn={{
                      text: "View Open Roles",
                      btnClassName: 'my-3',
                      clickFn: () => navigate(`/${MenuType.CONSTRUCTION}`),
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        </Container>
      </main>

    </PageLayout>
  );
}

export default Career;