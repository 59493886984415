import Image from "./Image";
import Slider from "react-slick";
import { Logos } from "../../utils/mapper/Mapper";

const Brands = () => {

  const ReactSlickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    cssease: "linear",
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  }

  return (
      <div className="bg-light-lilac pb-4">
        <h6 className="text-primary-darker text-center mt-4 mb-2 mb-md-1">Customers & Partners</h6>
        <p className={`font-size-16 text-medium text-center`}>We are supported by the right network of customers, partners and service providers</p>
        <div className="mt-4 mt-lg-3">
        <Slider {...ReactSlickSettings}>
          {Logos.map((item, index) => <Image
            alt={item.alt}
            src={item.src}
            className="mx-auto h-70" key={index}/>)}
        </Slider>
        </div>
      </div>
    
  )
}

export default Brands;