import Image from "./Image";
import { SourceType } from "../../modules/enums";
import { ICompanyInfoData } from "../../modules/components";

type Props = {
  source: string
  companyInfo: ICompanyInfoData[],
};

const CircleThumbnails = ({ source, companyInfo }: Props) => (

    <div className="circle-thumbnails-container h-auto mt-2">
      {companyInfo.map((data, index) =>
      <>
        <div className={` circle-thumbnail h-100 ${source === SourceType.ABOUT_US ? " flex-row flex-lg-column mx-1 text-start text-lg-center": ""}`}>
          <div className="mt-2">
            {source === SourceType.ABOUT_US ? <h6 className={`circle-thumbnail-icon ${data.iconStyle} p-2 mb-lg-4 me-4 me-lg-0`}>{data.number}</h6> : 
            <Image alt={data.alt} src={data.src} className={`circle-thumbnail-icon ${data.iconStyle} p-2`}/>
            }
          </div>
          { source === SourceType.HOME && <h6 className="my-3">{data.header}</h6>}
          <p className={data.textClassName}>{data.text}</p>
        </div>
        
        {source === SourceType.HOME &&  (
          <div className="home-thumbnail"/>  
        )}
      </>
        )}
    </div>
);


export default CircleThumbnails